body {
    font-family: "Roboto", sans-serif; 
    color: #222328;
    background-color: #F8F5ED;
}

html {
    -webkit-font-smoothing: antialiased;
}

a {
    color: #222328;
    text-decoration: none;
}

ul {
    background-color: #FFFFFF;
}

.crater-logo {
    width: auto;
    height: 35px;
}
